import axios from 'axios'
import {useState, useEffect} from 'react'
import {CircularProgress} from '@mui/material'
import {
	Box,
	Unstable_Grid2 as Grid,
	Typography,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Button
} from '@mui/material'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import {ExternalLink} from '../components/ExternalLink.js'

const dateFormatter = new Intl.DateTimeFormat('en-GB', {
	weekday: 'long',
	month: 'long',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	hour12: false
})

const YoutubeVideoEmbed = ({id, ...props}) => (
	<Box sx={{
		position: 'relative',
		width: '100%',
		paddingBottom: '56.25%'
	}} {...props}>
		<iframe
			src={`https://www.youtube.com/embed/${id}?modestbranding=1;rel=0`}
			title='YouTube video player'
			allow='clipboard-write; encrypted-media; web-share;'
			allowFullScreen
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				border: 0
			}}
		/>
	</Box>
)

const Event = ({
	image,
	event
}) => {
	const {
		name,
		time: {
			start
		},
		description,
		ticketUrl,
		uid,
		youtubeId
	} = event

	const eventDate = new Date(start)

	return (
		<Card
			id={uid}
			sx={{
				padding: 1,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<CardActionArea>
				<ExternalLink
					href={ticketUrl}
					sx={{textDecoration: 'none !important'}}
				>
					<CardMedia
						component='img'
						height='auto'
						image={image}
					/>
				</ExternalLink>
			</CardActionArea>
			<CardContent sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				my: 1
			}}>
				<Typography gutterBottom variant='h5' component='div'>
					{name}
				</Typography>
				<Box sx={{
					flexGrow: 1,
					overflow: 'auto'
				}}>
					<Typography
						variant='body2'
						color='text.secondary'
						sx={{
							whiteSpace: 'pre-line',
							maxHeight: '300px',
							"::-webkit-scrollbar": {
								width: '.4rem'
							}
						}}
					>
						{description}
					</Typography>
				</Box>
			</CardContent>
			{youtubeId && (
				<CardMedia sx={{px: 2}}>
					<YoutubeVideoEmbed id={youtubeId} />
				</CardMedia>
			)}
			<CardActions sx={{
				justifyContent: 'space-between',
				mt: 2,
				gap: 1
			}}>
				<Typography variant='subtitle1'>
					{dateFormatter.format(eventDate)}
				</Typography>
					<Button
						variant='contained'
						startIcon={<LocalActivityIcon />}
						disabled={event.status === 'cancelled'}
						href={ticketUrl}
						target='_blank'
						rel='noopener'
						sx={{textDecoration: 'none !important'}}
					>
						{event.status === 'cancelled' ? (
							'Cancelled'
						) : (
							'Tickets'
						)}
					</Button>
			</CardActions>
		</Card>
	)
}

export const Events = () => {
	const [loading, setLoading] = useState(true)
	const [eventData, setEventData] = useState([])
	const [fallbackMessage, setFallbackMessage] = useState(null)
	const odd = eventData.length % 2

	useEffect(() => {
		const now = new Date()
		const fetchData = async () => {
			try {
				const response = await axios({
					method: 'GET',
					url: '/api/events'
				})
				const events = Object.values(response.data || {})
				if (events.length > 0) {
					setEventData(events.sort((a, b) => a.datetimeStart - b.datetimeStart).filter(eventObj => {
						const yesterday = now.setUTCHours(5,0,0,0)-86400000
						return parseInt(eventObj.time?.start) && (new Date(eventObj.time.start).setUTCHours(0,0,0,0) >= yesterday)
					}))
					fallbackMessage && setFallbackMessage(null)
				} else {
					setFallbackMessage('There are no upcoming events at this time 😢')
				}	
			} catch (e) {
				console.error(e)
				setFallbackMessage('There was an error whilst loading the events 😢\r\nPlease try reloading this page.')
			} finally {
				setLoading(false)
			}
		}
		fetchData()
	}, [])

	return (
		<Box>
			<Grid
				container
				spacing={6}
			>
				{loading ? (
					<Grid xs={12} sx={{textAlign: 'center'}}>
						<CircularProgress />
					</Grid>
				) : (
					eventData.length > 0 ? (
						eventData.map(event => (
							<Grid key={event.uid} xl={odd ? 4 : 6} lg={odd ? 4 : 6} md={6} sm={12} xs={12}>
								<Event
									image={`/api/image/${event.uid}`}
									event={event}
								/>
							</Grid>
						))
					) : (
						<Grid xs={12} sx={{textAlign: 'center'}}>
							<Typography sx={{whiteSpace: 'pre-line'}}>
								{fallbackMessage}
							</Typography>
						</Grid>
					)
				)}
			</Grid>
		</Box>
	)
}