import {useState, useEffect} from 'react'
import axios from 'axios'
import {theme} from '../providers/theme'
import {
	Box,
	TextField,
	Alert
} from '@mui/material'
import {LoadingButton} from '@mui/lab/'
import {
	Send,
	SentimentVerySatisfied,
	SentimentVeryDissatisfied
} from '@mui/icons-material'


const buttonOptions = {
	ready: {
		label: 'Send',
		icon: <Send />,
		color: theme.palette.primary.main,
		buttonColor: 'primary',
		disabled: false,
		disabledStyles: {
			color: 'inherit'
		}
	},
	loading: {
		label: "\u2000",
		color: theme.palette.primary.main,
		buttonColor: 'inherit',
		disabled: false,
		disabledStyles: {
			color: 'inherit'
		}
	},
	success: {
		label: 'Thank you',
		icon: <SentimentVerySatisfied />,
		color: theme.palette.success.main,
		buttonColor: 'success',
		disabled: true,
		disabledStyles: {
			color: theme.palette.success.main
		}
	},
	failure: {
		label: 'Message failed to send',
		icon: <SentimentVeryDissatisfied />,
		color: theme.palette.error.main,
		buttonColor: 'error',
		disabled: true,
		disabledStyles: {
			color: theme.palette.error.contrastText,
			background: theme.palette.error.main
		}
	}
}


export const ContactForm = () => {
	const [status, setStatus] = useState('ready')
	const [failureMessage, setFailureMessage] = useState(null)
	const [values, setValues] = useState({
		name: '',
		email: '',
		enquiry: ''
	})

	const onSubmit = e => {
		e.preventDefault()
		setStatus('loading')
		if (failureMessage) {
			setFailureMessage(null)
		}

		axios.post('/api/contact', {
			...values
		},{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(response => {
			setStatus('success')
		}).catch(e => {
			console.error(e)
			setStatus('failure')
			const errors = e.response?.data?.errors || []
			const errorMessages = errors.filter(error => error.msg !== 'Invalid value')
			if (errorMessages.length > 0) {
				setFailureMessage(errorMessages.map(error => error.msg).join('\n'))
			}
		})
	}


	useEffect(() => {
		if (status === 'failure') {
			setTimeout(() => setStatus('ready'), 3000)
		}
	}, [status])

	const handleChange = e => {
		setValues({
			...values,
			[e.target.name]: e.target.value
		})
	}

	return (
		<Box
			component='form'
			noValidate
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2
			}}
			onSubmit={onSubmit}
		>
			<TextField
				fullWidth
				required
				onChange={handleChange}
				value={values.name}
				name='name'
				label='Name'
				type='text'
				disabled={buttonOptions[status].disabled}
				inputProps={{
					maxLength: '64'
				}}
				
			/>
			<TextField
				fullWidth
				required
				onChange={handleChange}
				value={values.email}
				name='email'
				label='E-mail'
				type='email'
				disabled={buttonOptions[status].disabled}
				inputProps={{
					maxLength: '254'
				}}
			/>
			<TextField
				fullWidth
				required
				onChange={handleChange}
				value={values.enquiry}
				name='enquiry'
				label='Enquiry'
				multiline
				rows={6}
				disabled={buttonOptions[status].disabled}
				inputProps={{
					maxLength: '2000'
				}}
			/>

			<LoadingButton
				variant='contained'
				type='submit'
				loading={status === 'loading'}
				color={buttonOptions[status].buttonColor}
				endIcon={buttonOptions[status].icon}
				disabled={buttonOptions[status].disabled}
				sx={{
					'&.Mui-disabled': {
						color: buttonOptions[status].disabledStyles
					}
				}}
			>
				{buttonOptions[status].label}
			</LoadingButton>

			{failureMessage && <Alert severity='error' sx={{whiteSpace: 'pre-line'}}>{failureMessage}</Alert>}
		</Box>
	)
}